// customer details api
export const userDetailsEndPoint = "/customer/profile"

// apis for services categories
export const servicesListEndpoint = "/common/categories"

export const serviceDetailsEndpoint = "/common/service"


export const bookServiceEndpoint = "/customer/create-booking"
export const serviceStep = "/customer/service-step"
export const checkDistance = "/customer/checkDistance"


// apis for booking data info

export const currentBookingEndpoint = "/customer/current-booking"


// order id for razor pay

export const razorpayOrderIdEndpoint = "/customer/create-order"

// booking api endpoint

export const cancelBookingAPIEndpoint = "/customer/cancel-booking"

export const bookingHistoryAPIEndpoint = "/customer/booking-history"

// review api endpoint

export const bookingReviewAPIEndpoint = "/customer/submit-review"

// addresses api endpoint

export const bookingAddressAPIEndpoint = "/customer/get-addresses";

export const AddAddressAPIEndpoint = "/customer/add-address"

// pass id for address to delete
export const deleteAddressAPIEndpoint = "/customer/delete-address"

// pass id for address to edit
export const editAddressAPIEndpoint = "/customer/edit-address"

// for default address setting

export const defaultAddressAPIEndpoint = "/customer/set-default-address"

// profile data api endpoint

export const profileDataAPIEndpoint = "/customer/profile"

export const updateProfileAPIEndpoint = "/common/update-profile"

export const changePasswordAPIEndpoint = "/common/change-password"

// notification api endpoint

export const notificationAPIEndpoint = "/common/my-notifications"

// contact us api
export const contactAPIEndpoint = "/common/contact-us"

// terms and conditions and privacy policy

export const tcPPAPIEndpoint = "/common/pages"

// refer a friend api endpoint

export const referFriendApiEndpoint = "/common/refer-a-friend"

// active offer discounts

export const activeOfferDiscountApiEndpoint = "/customer/my-discounts"

// subscription details

export const membershipAllPlansApiEndpoint = "/common/membership-plans"

export const subscriptionDetailsApiEndpoint = "/common/my-membership-plan"

export const buyMembershipPlanEndpoint = "/common/purchase-membership"

export const razorpayMembershipOrderIdEndpoint = ""

// check coupon

export const checkCouponEndpoint = "/common/check-coupon"

// logout endpoint

export const logoutAPIEndpoint = "/common/logout"